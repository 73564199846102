<template src="./ranking.html"></template>

<script>
export default {
  name: 'ranking',
  components: {},
  data() {
    return {
      ranking: null,
      first: null,
      seconds: null,
      third: null,
      loop: 0,
    };
  },
  created() {
    let store_id;
    if (Cookies.get('store_location_id') && Cookies.get('store_location_id') != undefined) {
      store_id = Cookies.get('store_location_id');
    }
    this.$MS_SOCO_PUBLIC_API_URL
      .get(`/products/top-sales`, {
        params: {
          filter: {
            is_active_in_offline_store: true,
            is_active_in_sociolla: true,
            store_id: store_id,
          },
        },
      })
      .then((res) => {
        const data = res.data.data;
        this.ranking = data.slice(0);
        this.first = this.ranking[0];
        this.seconds = this.ranking.slice(1, 5);
        setInterval(() => {
          if (this.loop === 0) {
            this.first = this.ranking[0];
            this.seconds = this.ranking.slice(1, 5);
            this.loop = 1;
          } else if (this.loop === 1) {
            this.first = this.ranking[5];
            this.seconds = this.ranking.slice(6, 10);
            this.loop = 0;
          }
        }, 5000);
        this.third = this.ranking.slice(5);
      });
  },
  methods: {
    imageError(e) {
      e.target.src = '/static/img/mask-station/default-image-product.png';
    },
  },
};
</script>
<style lang="scss">
@import 'ranking';
</style>
